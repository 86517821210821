<template>
  <v-container fluid class="pa-0">
    <v-card
      class="mx-auto elevation-0 py-2 mb-6"
      outlined
      :width="contentWidth"
    >
      <v-card-title class="px-6 pt-2">
        Meus planos e serviços ({{ hiredNumber }})
      </v-card-title>
      <Alert text dense class="my-1">
        <v-row align="center" no-gutters>
          <v-col class="grow blue--text text--lighten-1"
            >Saiba como contratar um plano para sua empresa</v-col
          >
          <v-col class="shrink">
            <v-play-video-button
              :smallbtn="true"
              justify_btn="center"
              color="info"
              title="Saiba como contratar um plano para sua empresa."
              id_youtube_video="t4rnQnDKHnE"
            />
          </v-col>
        </v-row>
      </Alert>
      <v-divider />

      <v-row
        :class="`text-center justify-start align-center flex-wrap py-4 px-5`"
        align-content="center"
        justify="start"
        justify-xs="center"
      >
        <v-col xs="12" sm="4" md="3" xl="2" v-if="!hasIntermediaryPartner">
          <PurchasedPlan is-new @click.native="addNewPlan" />
        </v-col>
        <v-col v-if="loading" xs="12" sm="4" md="3" xl="2">
          <div class="px-10 d-flex flex-column justify-center align-center">
            <v-progress-circular
              indeterminate
              color="primary"
              class=""
              v-if="loading"
            ></v-progress-circular>
            <span
              class="grey--text text--darken-2 mt-4 caption font-weight-bold"
              >Carregando...</span
            >
          </div>
        </v-col>

        <v-col
          v-for="(plan, i) in allPlans"
          :key="i"
          xs="12"
          sm="4"
          md="3"
          xl="2"
        >
          <PurchasedPlan
            :logo="plan.plan.short_code ? plan.plan.short_code : 'plans'"
            :plan="plan"
            :styles="
              plan.plan.name === editedCompanyPlan ? 'selected-card' : ''
            "
            :licenses-number="usersNumber"
            @click.native="goToPlanDetails(plan)"
          />
        </v-col>
      </v-row>

      <MoreInfoCardReseller
        id="companyPlanInfo"
        v-show="showModuleInfo"
        v-if="editedPlan.plan"
        :client-name="clientName"
        :logo="editedCompanyPlanLogo"
        :plan="editedPlan"
        client-view
      />
    </v-card>
  </v-container>
</template>
<script>
import { logBtnEvent } from "@/helpers/services/registerAnalytics";
import PurchasedPlan from "@/components/reseller/cards/PurchasedPlan";
import MoreInfoCardReseller from "@/components/reseller/cards/MoreInfoCardReseller";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Plans",
  components: { MoreInfoCardReseller, PurchasedPlan },
  data() {
    return {
      showModuleInfo: false,
      showPaymentPlan: false,
      editedPlan: {},
      editedCompanyPlan: "",
      editedCompanyPlanLogo: "",
      client: {},
    };
  },

  computed: {
    ...mapGetters([
      "company",
      "companyServices",
      "contractedPlans",
      "loadingClientPlans",
      "hasIntermediaryPartner",
      "contentWidth",
      "usersNumber",
      "hiredServicesNumber",
      "activePlansNumber",
      "clientActivePlans",
    ]),

    hiredNumber() {
      return this.hiredServicesNumber + this.activePlansNumber;
    },

    planKey() {
      return this.editedPlan ? this.editedPlan.plan.key : "";
    },

    loading() {
      return this.loadingClientPlans;
    },

    clientName() {
      return this.company.name;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    allPlans() {
      return [...this.clientActivePlans, ...this.companyServices];
    },
  },

  methods: {
    ...mapMutations(["setSelectedPlan", "setClientView"]),
    ...mapActions(["getPlans"]),

    goToPlanDetails(plan) {
      this.setSelectedPlan(plan.plan_key);
      this.showModuleInfo = true;
      this.editedCompanyPlan = plan.plan.name;
      this.editedCompanyPlanLogo = plan.plan.short_code;
      this.editedPlan = plan;
      this.editedPlan.products = plan.plan.products;
      this.editedPlan.licenses = this.usersNumber;
    },

    addNewPlan() {
      logBtnEvent({
        label: "Novo plano",
        action: "add_new_plan",
      });
      this.$router.push("/billing/add-plan");
    },
  },

  async beforeMount() {
    await this.getPlans();
    this.setClientView(true);
    this.client = Object.assign({}, this.company);
  },
};
</script>
